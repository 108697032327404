import axios from 'axios';
import { config } from 'config';
import { handleError } from './error';
import { encode } from 'base-64';

export const klaviyoUpsellLink = async (email: string) => {
  const apiKey = config.KLAVIYO_KEY;
  const identifyData = {
    token: apiKey,
    properties: {
      $email: email,
      express_e_book_generation: true,
    },
  };

  const encodedIdentifyData = encode(JSON.stringify(identifyData));

  try {
    const response = await axios.get(
      `https://a.klaviyo.com/api/identify?data=${encodeURIComponent(
        encodedIdentifyData,
      )}`,
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const klaviyoLogUserHasPaidAndRegistered = async (email: string) => {
  const apiKey = config.KLAVIYO_KEY;
  const identifyData = {
    token: apiKey,
    properties: {
      $email: email,
      has_paid_and_registered: true,
    },
  };

  const encodedIdentifyData = encode(JSON.stringify(identifyData));

  try {
    const response = await axios.get(
      `https://a.klaviyo.com/api/identify?data=${encodeURIComponent(
        encodedIdentifyData,
      )}`,
    );

    return response.data;
  } catch (error) {
    handleError(error);
  }
};
